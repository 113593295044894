import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/controltower/takeUpReferenceTableContamination',
  api,
  {
    importLogUrl: '/api/controltower/importLog'
  }
)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.currency.options = ctx.currencyOptions
}

service.changeTableDefaultById = async (id) => {
  try {
    const response = await api.put(
      `/api/controltower/takeUpReferenceTableContamination/changeTableDefaultById/${id}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

export default service
