<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      :reloadData="reloadData"
      @onReloadData="reloadData = false"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/takeUpReferenceTableContaminationService'
import takeUpReferenceTableContaminationRuleService from '../api/takeUpReferenceTableContaminationRuleService'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      reloadData: false,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getTakeUpReferenceTableContaminationRuleMeta() {
      const takeUpReferenceTableContaminationRuleMeta =
        await takeUpReferenceTableContaminationRuleService.getMetaWithValidation()
      helpers.processMetaDetails(
        takeUpReferenceTableContaminationRuleMeta,
        'takeUpReferenceTableContaminationRule',
        'referenceTableId',
        'referenceTable',
        takeUpReferenceTableContaminationRuleService
      )
      return takeUpReferenceTableContaminationRuleMeta
    },
    async onChangeTableDefaultByIdHandler(id) {
      await this.$brain.confirm(
        {
          title: this.$t('core.crud.attention'),
          subtitle: this.$tc(
            'controlTower.pages.takeUpReferenceTableContamination.changeDefaultMessage'
          )
        },
        async () => {
          try {
            await this.changeTableDefaultById(id)
          } catch (err) {
            this.notifyErrors(err)
            console.error('Failed to change default', err)
            this.notify.error({
              title: this.$tc('core.misc.error')
            })
          }
        }
      )
    },
    async changeTableDefaultById(id) {
      const resp = await service.changeTableDefaultById(id)
      if (this.hasErrors(resp)) {
        this.notifyErrors(resp)
      } else {
        this.notifySucess()
      }
      this.reloadData = true
    },
    notifySucess() {
      this.notify.success({ title: this.$t('application.misc.success') })
    },
    notifyErrors(error) {
      const errors = error?.error?.response?.data?.errors ?? []
      errors.forEach((error) => {
        this.notify.error({
          title: this.$t(error)
        })
      })
    },
    hasErrors(response) {
      return response.error
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getTakeUpReferenceTableContaminationRuleMeta()
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMetaWithValidation(),
        this.getMetaDetails()
      ])
      const defaultField = meta.fields.find((field) => field.id == 'default')
      defaultField.onValueChange = (changedValue, fields) => {
        fields.default.disabled = true
      }
      meta.actions = [
        {
          title: ({ node }) => {
            return `${node.data.default}` == 'true'
              ? this.$t(
                  'controlTower.pages.takeUpReferenceTableContamination.makeNotDefault'
                )
              : this.$t(
                  'controlTower.pages.takeUpReferenceTableContamination.makeDefault'
                )
          },
          className: ({ node }) => {
            return `${node.data.default}` == 'true'
              ? 'fad fa-toggle-on'
              : 'fad fa-toggle-off'
          },
          enable: true,
          callback: ({ node }) => {
            this.onChangeTableDefaultByIdHandler(node.data.id)
          }
        }
      ]
      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'controlTower.pages')
      this.metadata = meta
    })
  }
}
</script>
